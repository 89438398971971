<template>
  <div>
    <a-result
      status="404"
      title="404"
      sub-title="没有此页面"
    >
      <template #extra>
        <a-button type="link" @click="() => $router.go(-1)">
          双击返回上一页
        </a-button>
        <a-button type="link" @click="goLogin">
          重新登录
        </a-button>
      </template>
    </a-result>
  </div>
</template>

<script>
export default {
    data() {
        return {
            
        }
    },
    methods:{
        goLogin(){
            this.$router.replace('/login')
        }
    }
};
</script>

<style lang="less" scoped></style>
